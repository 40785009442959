import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io5";
import { Container, Col, Row } from "react-bootstrap";
import "./footer.css";

const FooterComponent = () => {
  return (
    <section className="footer-bg">
      <Container fluid>
        <Row>
          <div className="footer-container">
            <Col md={4}>
              <div className="footer-map">
                <div>
                  <iframe
                    className="map"
                    src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3937.9382461984565!2d76.51839407502158!3d9.249815890820583!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zOcKwMTQnNTkuMyJOIDc2wrAzMScxNS41IkU!5e0!3m2!1sen!2sin!4v1713633546721!5m2!1sen!2sin"
                    allowFullScreen={false}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <div className="footer-follow">
                <a style={{ textDecoration: "none" }} href="/#home">
                  <span className="follow-home "> Home</span>
                </a>
                <div className="footer-icon-link">
                  <a href="/#services" className="icon-text">
                    Our Services
                  </a>
                </div>
                <div className="footer-icon-link">
                  <a href="/#projects" className="icon-text">
                    Our Projects
                  </a>
                </div>
                <div className="footer-icon-link">
                  <a href="/#gallery" className="icon-text">
                    Our Gallery
                  </a>
                </div>
                <div className="footer-icon-link">
                  <a href="/#contactus" className="icon-text">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <div className="footer-follow">
                <span className="follow-text "> Follow Us</span>
                <div className="footer-icon-link">
                  <FaFacebook className="icons" />
                  <a
                    href="https://www.facebook.com/ekohomezbuilders?mibextid=LQQJ4d"
                    className="icon-text"
                  >
                    Facebook
                  </a>
                </div>
                <div className="footer-icon-link">
                  <FaInstagram className="icons" />
                  <a
                    href="https://www.instagram.com/ekohomez_builders?igsh=bTV4cnM5aTMzcjBn&utm_source=qr"
                    className="icon-text"
                  >
                    Instagram
                  </a>
                </div>
              </div>
            </Col>
            <Col md={3}>
              <div className="footer-meetus">
                <span className="follow-text">Meet Us</span>
                <div className="footer-icon-link">
                  <BsFillTelephoneFill className="icons" />
                  <a href="tel:+91 95445 06205" className="icon-text">
                    +91 95445 06205
                  </a>
                </div>
                <div className="footer-icon-link">
                  <IoMail className="icons" />
                  <a href="mailto:ekohomez.builders@gmail.com" className="icon-text">
                  ekohomez.builders@gmail.com
                  </a>
                </div>
                <div className="footer-icon-link">
                  <FaLocationDot className="icons" />
                  <a
                    href="https://maps.app.goo.gl/zh7ghKn7CeXiYtAw5?g_st=iw"
                    className="icon-text"
                  >
                    Thattarambalam, Mavelikara
                  </a>
                </div>
              </div>
            </Col>
            <Row>
            
          </Row>
          </div>
        </Row>
      </Container>
      <Col className="justify-content-center center">
              <p className="footer-span">
                Powered by 
                <a className="footer-span-link" href="https://www.nthindex.com">
                  Nthindex Software Solutions LLP
                </a>
              </p>
            </Col>
    </section>
  );
};

export default FooterComponent;
