import React, { useState, useEffect, useRef } from "react";
import { Container, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";
import Navbar from "react-bootstrap/Navbar";
import Brand from "./../../assets/EkoHomezBrand.svg";
import PhoneIcon from "./../../assets/ring.png";
import { HashLink } from "react-router-hash-link";

const NavbarComponent = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const sectionsRef = useRef([]);

  const stickNavbar = () => {
    if (window.scrollY > 100) {
      setStickyClass("sticky-top");
    } else {
      setStickyClass("");
    }
  };

  const handleScroll = () => {
    const scrollY = window.scrollY;
    let newActiveSection = "";

    sectionsRef.current.forEach((section) => {
      const { offsetTop, offsetHeight } = section;
      if (scrollY >= offsetTop && scrollY < offsetTop + offsetHeight) {
        newActiveSection = section.id;
      }
    });

    setActiveSection(newActiveSection);
  };

  useEffect(() => {
    sectionsRef.current = Array.from(
      document.querySelectorAll("[data-section]")
    );
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  return (
    
    <div className={`navbar-content ${stickyClass}`}>
      <Navbar expand="lg" collapseOnSelect className="navbar-container ">
        <Container>
          <Navbar.Brand href="/#home">
            <div className="brand-logo">
              <img src={Brand} alt="Brand Logo" className="logo-img" />
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-center"
          >
            <Nav className="">
              <Nav.Link
                   as={HashLink}
                   href="/#home"
                   to="/#home"
                   scroll={(el) => {
                     const yCoordinate =
                       el.getBoundingClientRect().top + window.scrollY;
                     const yOffset = -400;
                     window.scrollTo({
                       top: yCoordinate + yOffset,
                       behavior: "smooth",
                     });
                   }}
                className={activeSection === "home" ? "active" : ""}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={HashLink}
                href="/#services"
                to="/#services"
                scroll={(el) => {
                  const yCoordinate =
                    el.getBoundingClientRect().top + window.scrollY;
                  const yOffset = -400;
                  window.scrollTo({
                    top: yCoordinate + yOffset,
                    behavior: "smooth",
                  });
                }}
                className={activeSection === "services" ? "active" : ""}
              >
                Services
              </Nav.Link>
              <Nav.Link
                href="/#projects"
                className={activeSection === "projects" ? "active" : ""}
              >
                Our Projects
              </Nav.Link>
              <Nav.Link
                href="/#gallery"
                className={activeSection === "gallery" ? "active" : ""}
              >
                Our Gallery
              </Nav.Link>
              <Nav.Link
                href="/contactus"
                className={activeSection === "contactus" ? "active" : ""}
              >
                Contact Us
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <div className="contact-nav">
            <img src={PhoneIcon} alt="Phone Icon" className="phone" />
            <a
              href="tel:+91 95445 06205"
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="contact-content">
                <h6>Call Anytime</h6>
                <h5>+91 95445 06205</h5>
              </div>
            </a>
          </div>
        </Container>
      </Navbar>
    </div>
  );
};

export default NavbarComponent;
